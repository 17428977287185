<template>
  <div class="elv-chain-nav-container">
    <div class="elv-chain-icon">
      <div class="elv-chain-icon-content">
        <template v-if="props.type === 'entity'">
          <img
            v-if="entityStore.entityDetail?.logo"
            :src="entityStore.entityDetail?.logo"
            class="elv-chain-icon-content__logo"
            :onerror="useDefaultImage"
          />
          <img
            v-else
            src="@/assets/img/reports/default-entity-logo.png"
            class="elv-chain-icon-content__logo"
            :onerror="useDefaultImage"
          />
        </template>
        <SvgIcon v-else class="elv-chain-icon-content__svg" name="menu-my-projects" width="16" height="16" />
      </div>
      <div>
        <div class="elv-project-info">
          <div class="elv-project-info-name">
            {{ currentName }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="freeProjectReviewInfo.label" class="elv-project-review-info">
      <SvgIcon :name="freeProjectReviewInfo.icon" width="13" height="13" />
      <i18n-t
        v-if="freeProjectReviewInfo.icon === 'warning'"
        :keypath="freeProjectReviewInfo.label"
        tag="p"
        scope="global"
      >
        <template #time>
          <span>{{ dayjs(freeProjectReviewInfo.info).format('YYYY-MM-DD') || '' }}</span>
        </template>
        <template #reminder>
          <span class="elv-project-review-info-reminder">{{ t('common.reminder') }}</span>
        </template>
        <template #updateNow>
          <span class="elv-project-review-info-update-now" @click="onClickToUpdateProjectInfo">{{
            t('common.updateNow')
          }}</span>
        </template>
      </i18n-t>
      <span v-else>{{ t(freeProjectReviewInfo.label) }}</span>
    </div>
    <div v-if="projectUnSubscribedBanner.label" class="elv-project-review-info">
      <SvgIcon name="warning" width="13" height="13" />
      <i18n-t :keypath="projectUnSubscribedBanner.label" tag="p" scope="global">
        <template #time>
          <span
            >{{ dayjs(currentProject?.expiredDate).format('YYYY/MM/DD HH:mm') || '' }} &nbsp;{{
              currentEntityTimezone
            }}</span
          >
        </template>
        <template #handler>
          <span class="elv-project-review-info-update-now" @click="reSubscribeProjectPlan">{{
            t(projectUnSubscribedBanner.handlerTxt)
          }}</span>
        </template>
      </i18n-t>
    </div>
    <div v-if="projectPaymentFailedBanner.label" class="elv-project-review-info">
      <SvgIcon name="warning" width="13" height="13" />
      <i18n-t :keypath="projectPaymentFailedBanner.label" tag="p" scope="global">
        <template #time>
          <span class="elv-project-review-info-reminder"
            >{{ dayjs(currentProject?.expiredDate).format('YYYY/MM/DD HH:mm') || '' }} &nbsp;{{
              currentEntityTimezone
            }}</span
          >
        </template>
        <template #handler>
          <span class="elv-project-review-info-update-now" @click="retryPaymentProjectPlan">{{
            t(projectPaymentFailedBanner.handlerTxt)
          }}</span>
        </template>
      </i18n-t>
    </div>
    <div v-if="projectUpgradePlanBanner.label" class="elv-project-review-info">
      <SvgIcon name="warning" width="13" height="13" />
      <i18n-t :keypath="projectUpgradePlanBanner.label" tag="p" scope="global">
        <template #time>
          <span>{{ dayjs(currentProject?.createdAt).format('YYYY-MM-DD') || '' }}</span>
        </template>
        <template #handler>
          <span class="elv-project-review-info-update-now" @click="upgradeProjectPlan">{{
            t(projectUpgradePlanBanner.handlerTxt)
          }}</span>
        </template>
      </i18n-t>
    </div>
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
    <ResubscriptionDialog ref="resubscriptionDialogRef" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import timezoneList from '@/config/timezone'
import { judgeTalkToSalesLink } from '@/lib/utils'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'
import ResubscriptionDialog from '../ProjectSetting/components/ResubscriptionDialog.vue'
import UpgradeProjectPlanDialog from '../ProjectSetting/components/UpgradeProjectPlanDialog.vue'
import { ProjectPermissionLevel, ProjectReviewStatus, ProjectSubscribeStatus } from '#/ProjectTypes'

const props = defineProps({
  type: {
    type: String,
    default: 'entity'
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const projectStore = useProjectStore()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const resubscriptionDialogRef = useTemplateRef('resubscriptionDialogRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')

const unActiveFreeProject = computed(() => {
  return globalStore.projectList.find(
    (project) =>
      project.plan === ProjectPermissionLevel.FREE && project.projectReview?.status === ProjectReviewStatus.UNACTIVATION
  )
})

const currentProject = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  const projectId = entityStore.entityDetail?.projectId || (route.params?.projectId as string) || ''
  return globalStore.projectDetail(projectId)
})

const enableUpgradeProject = computed(() => {
  return (
    currentProject.value?.plan === ProjectPermissionLevel.BASIC ||
    currentProject.value?.plan === ProjectPermissionLevel.FREE
  )
})

const currentName = computed(() => {
  if (props.type === 'project') {
    return currentProject.value?.name || ''
  }
  const entityIdInfo = route.params?.entityId as string
  if (!isEmpty(unActiveFreeProject.value) && !entityIdInfo) {
    return unActiveFreeProject.value.name
  }
  return entityStore.entityDetail?.name
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityStore.entityDetail?.timezone)?.timezone ?? 'UTC+00:00'
})

const freeProjectReviewInfo = computed(() => {
  if (!currentProject.value || currentProject.value?.plan !== ProjectPermissionLevel.FREE)
    return { label: '', icon: '' }
  if (currentProject.value?.projectReview?.status === 'WAIT_DATA_UPDATE') {
    return {
      label: 'message.freeProjectReviewFailedTipInfo',
      icon: 'warning',
      info: dayjs(currentProject.value?.projectReview?.expiredDate).format('YYYY/MM/DD') || ''
    }
  }
  if (currentProject.value?.projectReview?.status === 'DATA_UPDATE') {
    return { label: 'message.freeProjectReviewSuccessTipInfo', icon: 'information--filled' }
  }
  return { label: '', icon: '' }
})

const projectUnSubscribedBanner = computed(() => {
  if (!currentProject.value) return { label: '', handlerTxt: '' }
  if (currentProject.value.subscribeStatus === ProjectSubscribeStatus.PAUSED) {
    return { label: 'message.projectUnSubscribedBanner', handlerTxt: 'common.resubscribe' }
  }
  return { label: '', handlerTxt: '' }
})

const projectPaymentFailedBanner = computed(() => {
  if (!currentProject.value) return { label: '', handlerTxt: '' }
  if (currentProject.value.subscribeStatus === ProjectSubscribeStatus.PAYMENT_FAILED) {
    return { label: 'message.projectPaymentFailedBanner', handlerTxt: 'button.retryPayment' }
  }
  return { label: '', handlerTxt: '' }
})

const projectUpgradePlanBanner = computed(() => {
  if (!currentProject.value) return { label: '', handlerTxt: '' }
  if (currentProject.value.subscribeStatus === ProjectSubscribeStatus.EXCEEDED) {
    return {
      label: 'message.projectUpgradePlanBanner',
      handlerTxt: enableUpgradeProject.value ? 'button.upgradePlan' : 'button.talkToSales'
    }
  }
  return { label: '', handlerTxt: '' }
})

const onClickToUpdateProjectInfo = () => {
  router.push({ name: 'project-settings', params: { projectId: currentProject.value?.projectId } })
}

/**
 * 重新订阅
 */
const reSubscribeProjectPlan = () => {
  resubscriptionDialogRef.value?.changeReSubscriptionDialogStatus()
}

/**
 * 重新支付
 */
const retryPaymentProjectPlan = async () => {
  try {
    const currentRouterPath = router.resolve({
      query: {
        projectId: currentProject.value?.projectId
      }
    }).href
    const url = `${import.meta.env.VITE_APP_BASE_URL}${currentRouterPath}`
    const paymentData = await ProjectApi.paymentGenerate(currentProject.value?.projectId || '', {
      url
    })
    window.open(paymentData.data, '_self')
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

/**
 * 升级项目
 */
const upgradeProjectPlan = () => {
  // 基础版支持自助升级
  if (enableUpgradeProject.value) {
    upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
    return
  }
  // 其他版本项目不支持自助升级项目，跳转到联系销售界面
  judgeTalkToSalesLink(user.value?.name || '', user.value?.email)
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>

<style scoped lang="scss">
.elv-chain-nav-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  position: relative;

  .elv-chain-icon {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 20px;
    height: 48px;

    .elv-chain-icon-content {
      margin-right: 8px;
      align-self: flex-start;
      height: 100%;
      display: flex;
      align-items: center;

      .elv-chain-icon-content__logo {
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 50px;
      }

      .elv-chain-icon-content__svg {
        fill: #b3bfce;
      }
    }

    .elv-project-info {
      display: flex;
      align-items: center;
      height: 31px;

      .elv-project-info-name {
        font-family: 'Plus Jakarta Sans';
        font-weight: 700;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        color: #000;
      }

      .elv-project-info-token {
        height: 21px;
        box-sizing: border-box;
        padding: 0px 8px;
        font-family: 'Unbounded Variable';
        font-weight: 400;
        font-size: 11px;
        color: $elv-color-858B92;
        border-radius: 2px;
        border: 1px solid $elv-color-858B92;
        margin-left: 8px;
        margin-top: 4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
      }
    }
  }

  .elv-project-review-info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 100%;
    background-color: #faffe7;
    border-top: 1px solid #dde1e6;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-weight: 400;
    color: #0e0f11;
    pointer-events: none;

    svg {
      margin-right: 6px;
    }

    .elv-project-review-info-reminder {
      font-weight: 700;
    }

    .elv-project-review-info-update-now {
      color: #1343bf;
      text-decoration: underline;
      pointer-events: all;
      cursor: pointer;
    }
  }
}
</style>
